import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";
import { Button, Divider } from "antd";
import LFTLogo from "../../assets/images/footer-logo.png";
import listItemIcon from "../../assets/images/list-item-icon.svg";
import { useDispatch } from "react-redux";
import { whoWeAreClick } from "../../Reducers/miniCartSlice";


function Footer() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const screenWidth = window.innerWidth >= 1024;


  const quickLinks = [
    {
      title: "Who we Are",
      route: "who-we-are",
    },
    {
      title: "Fundraisers",
      route: "/fundraisers",
    },
    {
      title: "Contact Us",
      route: "/contact-us",
    },
  ];

  const sitePolicyPages = [
    {
      title: "Privacy Policy",
      route: "/privacy-policy",
    },
    {
      title: "Terms of Service",
      route: "/terms-of-service",
    },
  ];

  // const socials = [
  //   {
  //     social: "Youtube",
  //     icon: youtube,
  //     link: "https://www.youtube.com/",
  //   },
  //   {
  //     social: "Facebook",
  //     icon: facebook,
  //     link: "",
  //   },
  //   {
  //     social: "Instagram",
  //     icon: instagram,
  //     link: "",
  //   },
  //   {
  //     social: "Twitter",
  //     icon: twitter,
  //     link: "",
  //   },
  // ];

  // COMPONENT TO RENDER THE SOCIALS ICONS
  function SocialsIcon(icon, link, alt) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <img className="socials-icons" src={icon} alt={alt} />
      </a>
    );
  }

  // COMPONENT TO RENDER LINKS

  const handleNavigation = async (route) => {
    if (route === "who-we-are") {
      await navigate("/");
      dispatch(whoWeAreClick(true))
    } else {
      navigate(route);
      dispatch(whoWeAreClick(false))
    }
  };

  

  function IndividualLinkComponent(linkText, linkRoute) {
    return (
      <div className="link">
        <img className="li-icon" src={listItemIcon} alt={linkText} />

<p onClick={() => handleNavigation(linkRoute)}>{linkText}</p>

      </div>
    );
  }

  return (
    <div className="Footer">
      <div className="sectionOne">
        <p className="tagline">
          Be part of the Team! Support Active Rugby Fundraisers
        </p>
        <div className="btns">
          <Button
            className="footer-btn"
            onClick={() => navigate("/fundraisers")}
          >
            Active Fundraisers
          </Button>
          <Button
            className="footer-transparent-btn"
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </Button>
        </div>
      </div>
      <div className="sectionTwo">
        <div className="links">
          <div className="col-custom-1">
            <div className="footer-logo">
              <img
                className="footer-logo-img"
                src={LFTLogo}
                alt="LFTLogo"
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
          </div>
          <div className="grid">
            {screenWidth && <div className="cols"></div>}
            <div className="cols">
              <div className="footer-heading">Quick Links</div>
              <div className="footer-heading-container">
                {quickLinks.map((link) => {
                  return IndividualLinkComponent(link.title, link.route);
                })}
              </div>
            </div>
            <div className="cols">
              <div className="footer-heading">Site Policy</div>
              <div className="footer-heading-container">
                {sitePolicyPages.map((link) => {
                  return IndividualLinkComponent(link.title, link.route);
                })}
              </div>
            </div>
            {/* <div className="cols">
              <div className="footer-heading">Let's Get In Touch</div>
              <a href="mailto:Hello@email.com" className="link email">
                {IndividualLinkComponent("Hello@email.com")}
              </a>
              <a href="tel:+7348520901" className="link phone">
                {IndividualLinkComponent("+734 852 0901")}
              </a>
            </div> */}
            {/* <div className="cols">
              <div className="footer-heading">Let's Get In Touch</div>
              <div className="adr-details address">
                <div className="link address">
                  <img src={locationIcon} alt="Location Icon" />
                  <p>#000, 1st cross, 1st main, B block</p>
                </div>
                <p>3 stage, Area name</p>
                <p>City Name</p>
                <p>State Name</p>
                <p>Country Name</p>
                <p>Pincode</p>
              </div>
            </div> */}
          </div>
        </div>
        <Divider className="footer-divider" />
        <div className="socials">
          <div className="trademark">
            <span>
              &copy; 2024 LFT Global Corporation. All rights reserved.
            </span>
          </div>
          {/* <div className="social-container">
            {socials.map((item) => {
              return SocialsIcon(item.icon, item.link, item.social);
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
