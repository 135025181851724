import React from "react";
import "../Login/LoginComponent.scss";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import usaImage from "../../../assets/images/usa-flag.png";
import "./RegisterComponent.scss";
import { CUSTOMER_CREATE } from "../../../services/Register/RegisterQueries";

function RegisterComponent() {
  const navigate = useNavigate();
  const [customerCreate, { data, loading, error }] =
    useMutation(CUSTOMER_CREATE);

  const onFinish = async (values) => {
    console.log("Success:", values);
    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      phoneNumber,
    } = values;
    try {
      if (
        !email ||
        !password ||
        !confirmPassword ||
        !firstName ||
        !lastName ||
        !phoneNumber
      ) {
        return toast.error("Please fill all the details");
      }
      if (confirmPassword.toLowerCase() !== password.toLowerCase()) {
        return toast.error(
          "Passwords do not match. Please ensure both passwords are the same and try again."
        );
      }
      const { data } = await customerCreate({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phone: `+1${phoneNumber}`,
            password: password,
          },
        },
      });
      console.log("data", data);
      if (data.customerCreate?.customer?.id) {
        debugger
        toast.success("Register is successfully done, Please try to login");
        navigate("/login");
      } else {
        toast.error(`${data?.customerCreate?.customerUserErrors[0]?.message || 'Sorry something went wrong'}`);
      }  

    } catch (er) {
      console.log("error", error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="loginWrapper">
      <div className="container">
        <div className="leftContainer">
          <h1 className="lftText heightOfLft">Let's Fundraise </h1>
          <h1 className="lftText">Together!</h1>
          <h1 className="orangeText">so no one's left behind</h1>
          <div className="loginBox">
            <div className="formWrapper">
              <Form
                name="form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <h1 className="formHeading Futura">REGISTER</h1>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  className="Futura"
                >
                  <Input className="input" autoComplete="off" />
                </Form.Item>

                <Form.Item label="Last name" name="lastName">
                  <Input className="input" autoComplete="off" />
                </Form.Item>

                <Form.Item label="Phone Number" name="phoneNumber">
                  <div className="phoneNumberWrapper">
                    <img className="flag" src={usaImage} />
                    <Input
                      className="input"
                      autoComplete="off"
                      maxLength={10}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  label="E-mail id"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input className="input" autoComplete="off" />
                </Form.Item>

                <Form.Item label="Password" name="password">
                  <Input.Password className="input" autoComplete="new-password" />
                </Form.Item>

                <Form.Item label="Confirm Password" name="confirmPassword">
                  <Input.Password className="input" autoComplete="off" />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit" className="button">
                    Register
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        <div className="rightContainer">rightContainer</div>
      </div>
    </div>
  );
}

export default RegisterComponent;
