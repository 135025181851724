import React from "react";
import bgImage from "../../../assets/images/hero-img.jpg";
import HeroComponent from "./Hero";
import WhoWeAreComponent from "./WhoWeAre";
import CampaignsComponent from "./Campaigns";
import FundraiserInfoComponent from "./Fundraiser";
import { Helmet } from "react-helmet";
export default function HomePage() {
  return (
    <div className="home-container">
      <Helmet>
        <meta property="og:title" content="Lets Fundraise Together!" />
        <meta
          property="og:description"
          content="Be part of the team! Your generosity helps provide vital resources and opportunities. Help us strengthen and grow rugby in the US!"
        />
        <meta property="og:image" content={bgImage || ""} />
      </Helmet>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "50% 30%",
          boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, .3)",
        }}
      >
        <HeroComponent />
      </div>
      <div className="active-campaigns">
        <CampaignsComponent />
      </div>
      <div id="who-we-are" className="who-info">
        <WhoWeAreComponent />
      </div>
      <div className="fundraising">
        <FundraiserInfoComponent />
      </div>
    </div>
  );
}
