import React from "react";
import { Row, Col, Divider } from "antd";
import "../Campaigns/ListingCampaigns.scss";
import { useNavigate } from "react-router";
import { ArrowRightOutlined } from "@ant-design/icons";

const isMobile = window.innerWidth < 768;

const getOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const ImageColumn = ({ campaign }) => (
  <Col xs={24} sm={24} md={8}>
    <div>
      <img
        src={campaign?.carousel_imageUrl || campaign?.imageUrl}
        alt={campaign?.title}
        className="card-image"
      />
    </div>
  </Col>
);

const ContentColumn = ({ index, campaign }) => {
  const navigate = useNavigate();
  const Id = campaign?.id;
  const campaignID = Id?.match(/\d+$/)[0];

  const handleViewDetails = (products) => {
    // Navigate to the fundraiser-details page
    navigate(`/fundraisers-details/${campaignID}`);
  };

  const startDate = new Date(campaign?.start_date);
  const endDate = new Date(campaign?.end_date);

  // Format start date
  const formattedStartDate =
    startDate.getDate() +
    getOrdinalSuffix(startDate.getDate()) +
    " " +
    startDate.toLocaleString("en-US", { month: "short" });

  // Format end date
  const formattedEndDate =
    endDate.getDate() +
    getOrdinalSuffix(endDate.getDate()) +
    " " +
    endDate.toLocaleString("en-US", { month: "short" });

  // Combine formatted dates
  const formattedDateRange = `${formattedStartDate} to ${formattedEndDate}`;

  return (
    <Col xs={24} sm={24} md={16}>
      <div className={`card-description ${index === "odd" ? "right" : "left"}`}>
        <div
          className={`card-head-container ${
            isMobile ? "mobile" : index === "odd" ? "right" : "left"
          }`}
        >
          <div className="card-headings-sub-container">
            <div className="date-place">{campaign?.location}</div>
            <h2 className="card-heading">{campaign?.title?.toUpperCase()}</h2>
            <p className="card-sub-heading">{campaign?.short_description}</p>
          </div>
          <div className="date">{formattedDateRange}</div>
        </div>
        <p className="card-details">{campaign?.description}</p>
        <Divider className="custom-divider" dashed />
        <div
          className={`card-sub-description ${
            isMobile ? "mobile" : index === "odd" ? "row-reverse" : "row"
          }`}
        >
          <div className="detail-button-container">
            <span onClick={() => handleViewDetails()}>
              View Details <ArrowRightOutlined />
            </span>
          </div>
        </div>
        <Divider className="custom-divider" dashed />
      </div>
    </Col>
  );
};

function ListingCampaigns({ index, campaign, home }) {
  return (
    <div className={`main-container ${home ? "home" : ""}`}>
      <Row gutter={[32, 32]}>
        {isMobile ? (
          <>
            <ImageColumn campaign={campaign} />
            <ContentColumn campaign={campaign} />
          </>
        ) : (
          <>
            {/* Left half for image if index is even, otherwise right half */}
            {index % 2 === 0 ? (
              <ImageColumn campaign={campaign} />
            ) : (
              <ContentColumn index="odd" campaign={campaign} />
            )}
            {/* Right half for content if index is even, otherwise left half */}
            {index % 2 === 0 ? (
              <ContentColumn index="even" campaign={campaign} />
            ) : (
              <ImageColumn campaign={campaign} />
            )}
          </>
        )}
      </Row>
    </div>
  );
}

export default ListingCampaigns;
