import React, { useState } from "react";
import "./LoginComponent.scss";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_ACCESS_TOKEN_CREATE } from "../../../services/Login/LoginQueries";
import { CUSTOMER_RECOVER } from "../../../services/Password/ForgotPasswordQueries";

function LoginComponent() {
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState("Login");
  const [customerAccessTokenCreate, { data, loading, error }] = useMutation(
    CUSTOMER_ACCESS_TOKEN_CREATE
  );
  const [customerRecover, { status, Error, result }] =
    useMutation(CUSTOMER_RECOVER);

  const onFinish = async (values) => {
    const { email, password } = values;
    try {
      if (email?.length && password?.length) {
        const { data } = await customerAccessTokenCreate({
          variables: { email, password },
        });
        if (data?.customerAccessTokenCreate?.customerUserErrors?.length) {
          toast.error(
            `${data?.customerAccessTokenCreate?.customerUserErrors[0]?.message ||
            "Sorry something went wrong"
            }`
          );
        } else {
          const token =
            data?.customerAccessTokenCreate?.customerAccessToken?.accessToken;
          toast.success("Login successfully done");
          navigate("/");
        }
      } else {
        toast.error("Please fill all the details");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const resetPassword = async (values) => {
    const { email } = values;
    try {
      if (!email) {
        return toast.error(
          "Please Enter your email id to receive forgot password link"
        );
      }
      const { data } = await customerRecover({
        variables: {
          email,
        },
      });
      if (data?.customerRecover?.customerUserErrors.length) {
        toast.error(`${data?.customerRecover?.customerUserErrors[0]?.message || 'Sorry something went wrong'} `)
      } else {
        toast.success('Password reset information has been sent to your registered email')
        navigate('/login')
      }

    } catch (error) {
      console.log("error", error);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="loginWrapper">
      <div className="container">
        <div className="leftContainer">
          <h1 className="lftText heightOfLft">Let's Fundraise </h1>
          <h1 className="lftText">Together!</h1>
          <h1 className="orangeText">so no one's left behind</h1>
          {activeComponent === "Login" ? (
            <div className="loginBox">
              <div className="formWrapper">
                <Form
                  name="form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <h1 className="formHeading Futura">Login</h1>
                  <Form.Item
                    label="E-mail id"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                    ]}
                    className="Futura"
                  >
                    <Input className="input" autoComplete="off" />
                  </Form.Item>

                  <Form.Item label="Password" name="password">
                    <Input.Password
                      className="input"
                      autoComplete="new-password"
                    />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit" className="button">
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div className="loginFooterWrapper">
                <div
                  className="forgotPassword Futura text"
                  onClick={() => {
                    setActiveComponent("forgotPassword");
                  }}
                >
                  <span>Forgot Password?</span>
                </div>
                <div
                  className="register Futura text"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  <span>Register</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="loginBox">
              <div className="formWrapper">
                <Form
                  name="resetForm"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={resetPassword}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                >
                  <h1 className="formHeading Futura">Forgot Password</h1>
                  <Form.Item
                    label="E-mail id"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                    ]}
                    className="Futura"
                  >
                    <Input className="input" />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button type="primary" htmlType="submit" className="button">
                      RESET PASSWORD
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </div>

        <div className="rightContainer">rightContainer</div>
      </div>
    </div>
  );
}

export default LoginComponent;
