import React from "react";
import "../Policies/Policies.scss";

function AboutUs() {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-main-heading privacy-heading">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </div>
      <div className="privacy-heading">Lorem ipsum dolor sit</div>
      <div className="privacy-para intro">
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse incidunt
        architecto earum sit laudantium magni eius, ratione provident fugiat
        ducimus tenetur animi commodi laborum blanditiis modi iste perspiciatis
        aut minus.
      </div>
      {[0, 0, 0].map(() => (
        <div className="section">
          <div className="privacy-heading">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse
            incidunt architecto earum sit laudantium magni eius.`
          </div>
          <ul className="privacy-section-content">
            {[0, 0].map(() => (
              <li className="privacy-para">
                <div>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Reprehenderit ullam illum porro facilis consectetur, eveniet
                  minus? Earum impedit, officia sapiente, sed quaerat laboriosam
                  debitis incidunt ea, asperiores voluptate repellendus.
                  Necessitatibus?
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default AboutUs;
