import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Utils/ScrollToTop.jsx";
import HomePage from "./Pages/Common/Home/index.jsx";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import AboutUsPage from "./Pages/Common/AboutUs/AboutUs.jsx";
import ContactUsPage from "./Pages/Common/ContactUs/ContactUs.jsx";
import CampaignsPage from "./Pages/Common/Campaigns/index.jsx";
import LoginPage from "./Pages/Common/Login/LoginComponent.jsx";
import RegisterPage from "./Pages/Common/Register/RegisterComponent.jsx";
import ViewCartPage from "./Pages/Common/ViewCart.jsx";
import CampaignDetailsPage from "./Pages/Common/Campaigns/CampaignDetailsPage.jsx";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_CART_ITEMS } from "./services/ViewQueries.js";
import { clearLocalStorage } from "./Utils/LocalStorageServices.js";
import ProductDetail from "./Pages/Common/ProductDetail/ProductDetail.jsx";
import PrivacyPolicy from "./Pages/Common/Policies/PrivacyPolicy.jsx";
import TermsOfService from "./Pages/Common/Policies/TermsOfService.jsx";
import OrderPage from "./Pages/Common/OrderPage/OrderPage.jsx";
import ThankYouPage from "./Pages/Common/ThankYou/ThankYou.jsx";

function App() {
  const [getData, { loading, error, data }] = useLazyQuery(GET_CART_ITEMS);
  const cartId = localStorage.getItem("cartId");

  useEffect(() => {
    if (data?.cart === null) {
      const result = clearLocalStorage()
    }
  }, [data]);

  useEffect(() => {
    if (cartId) {
      getData({
        variables: {
          id: cartId,
        },
      });
    } else return;
  }, []);
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        limit={5}
      ></ToastContainer>
      <BrowserRouter>
        <Header />

        <div className="components-container">
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/who-we-are" element={<AboutUsPage />} />
              <Route path="/fundraisers" element={<CampaignsPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route
                path="/fundraisers-details/:id"
                element={<CampaignDetailsPage />}
              />
              <Route path="/view-cart" element={<ViewCartPage />} />
              <Route path='/product-detail/:id' element={<ProductDetail />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms-of-service' element={<TermsOfService />} />
              <Route path='/order/:id' element={<OrderPage />} />
              <Route path='/thank-you' element={<ThankYouPage />} />

            </Routes>
          </ScrollToTop>
        </div>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
