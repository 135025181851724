import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns } from "../../../Reducers/campaignsSlice";
import ListingCampaigns from "../../../Components/Campaigns/ListingCampaigns";
import "./index.scss";
import bgImage from "../../../assets/images/campaignListing.jpg";
import Loader from "../../../Components/Loader/Loader";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import heroImg from "../../../assets/images/hero-img.jpg";

function CampaignsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { campaigns, loading, error } = useSelector((state) => state.campaigns);

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="no-campaigns">
      <Helmet>
        <meta property="og:title" content="Lets Fundraise Together!" />
        <meta
          property="og:description"
          content="Be part of the team! Your generosity helps provide vital resources and opportunities. Help us strengthen and grow rugby in the US!"
        />
        <meta property="og:image" content={heroImg || ""} />
      </Helmet>
      <div className="hero-container">
        <div
          className="hero"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        ></div>
      </div>
      <div className="heading-container">
        <h3 className="heading">ACTIVE FUNDRAISERS</h3>
      </div>
      {error ? (
        <div className="error-container">
          <p>
            Oops! We encountered an issue while loading the fundraisers. Please
            try again later.
          </p>
          <div className="error-btn-container">
            <Button onClick={() => navigate("/")} className="btn">
              Go Back
            </Button>
          </div>
        </div>
      ) : !campaigns || !campaigns.length ? (
        <div className="error-container">
          <p>Sorry, No Active Fundraisers at the moment.</p>
          <div className="error-btn-container">
            <Button onClick={() => navigate("/")} className="btn">
              Go Back
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {campaigns.map((campaign, index) => (
            <ListingCampaigns key={index} index={index} campaign={campaign} />
          ))}
        </div>
      )}
    </div>
  );
}

export default CampaignsPage;
