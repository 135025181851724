import React from "react";
import privacyPolicyContent from "./PrivacyPolicy.json";
import "./Policies.scss";

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-main-heading privacy-heading">
        {privacyPolicyContent.title}
      </div>
      <div className="privacy-heading">{privacyPolicyContent.updated}</div>
      <div className="privacy-para intro">
        {privacyPolicyContent.introduction}
      </div>
      {privacyPolicyContent.sections.map((section, index) => (
        <div key={index} className="section">
          <div className="privacy-heading">{`${index + 1}. ${
            section.title
          }`}</div>
          {section.subtitle && (
            <div className="privacy-sub-heading privacy-para">
              {section.subtitle}
            </div>
          )}
          {index <= 3 ? (
            <ul className="privacy-section-content">
              {section.content.map((paragraph, idx) => (
                <li key={idx} className="privacy-para">
                  <div>{paragraph}</div>
                </li>
              ))}
            </ul>
          ) : (
            section.content.map((paragraph, idx) => (
              <div key={idx} className="privacy-para">
                {paragraph}
              </div>
            ))
          )}
        </div>
      ))}
    </div>
  );
}
