import React from 'react'
import './ThankYou.scss'
import { useNavigate } from 'react-router-dom';

import { CheckCircleOutlined } from '@ant-design/icons';
const ThankYou = () => {
    const navigate = useNavigate()
  return (
    <div className='thank-you-container'>
        <div className='thank-you-heading'><h1>Thank you !</h1></div>
        <div className='thank-you-text'> <p> Thank you for reaching out! We got your request and we will get in touch.</p>
        <p> Meanwhile, take a look at our <span className='fundraiser-link' onClick={() => {navigate('/fundraisers')}}>Fundraisers</span></p>
         </div>
         <div>
         <CheckCircleOutlined className='thank-you-circle'/>

         </div>
    </div>
  )
}

export default ThankYou