const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCampaignDetail = async (campaignId) => {
  try {
    const response = await fetch(
      `${API_URL}/public/campaigns/getSpecificCampaigns?id=${campaignId}`
    );

    if (!response.ok) {
      throw new Error('Error Fetching Fundraiser Details');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
