import { gql } from "@apollo/client";

export const CART_LINES_ADD_MUTATION = gql`
  mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              cost {
                amountPerQuantity {
                  amount
                }
                subtotalAmount {
                  amount
                }
                totalAmount {
                  amount
                }
              }
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount
                  }
                  product {
                    createdAt
                    featuredImage {
                      src
                    }
                    title
                    tags
                  }
                }
              }
              attributes {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;


export const CART_CREATE_MUTATION = gql`
  mutation createCart($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              cost {
                amountPerQuantity {
                  amount
                }
                subtotalAmount {
                  amount
                }
                totalAmount {
                  amount
                }
              }
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount
                  }
                  product {
                    createdAt
                    featuredImage {
                      src
                    }
                    title
                    tags
                  }
                }
              }
              attributes {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;