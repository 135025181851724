import React from "react";
import "./Loader.scss";

export default function Loader({ calculateHeight = true,customWidth }) {
  return (
    <div
    style={{width:customWidth ? `${customWidth}px`  :''}}
      className={
        calculateHeight ? "loader-container" : "loader-container height"
      }
    >
      <div className="loader"></div>
    </div>
  );
}
