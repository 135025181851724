import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./Hero.scss";
import playIcon from "../../../assets/images/play-icon.png";

export default function HeroComponent() {
  const navigate = useNavigate();
  return (
    <>
      <div className="hero-content-container">
        <div className="left-container">
          <div className="hero-text">
            {getFundraiseForDifScreens()}
            <div className="hero-subtitle">So Every Team Can Succeed.</div>
          </div>
          <div className="btns-container">
            <div className="campaigns-btn">
              <Button
                className="hero-orange-btn"
                onClick={() => navigate("/fundraisers")}
              >
                OUR Fundraisers
              </Button>
            </div>
            {/* Removed for now  */}
            {/* <div className="watch-btn">
              <img className="play-btn" src={playIcon} alt="" />
              <p className="watch-text">Watch video</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );

  function getFundraiseForDifScreens() {
    return (
      <>
        {window.innerWidth >= 760 ? (
          <div className="hero-title">
            LET'S FUNDRAISE
            <br /> TOGETHER!
          </div>
        ) : (
          <div className="hero-title">LET'S FUNDRAISE TOGETHER!</div>
        )}
      </>
    );
  }
}
