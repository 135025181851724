import { configureStore } from "@reduxjs/toolkit";
import miniCartSlice from "./miniCartSlice";
import campaignsReducer from "./campaignsSlice";

const store = configureStore({
    reducer:{
    miniCart:miniCartSlice,
    campaigns: campaignsReducer,
    }
})

export default store;