const API_URL = process.env.REACT_APP_API_BASE_URL;

export const contactUsSubmitClick = async (payload) => {
  try {
    const response = await fetch(
      `${API_URL}/public/contact`,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(payload)
      }
    );

    if (!response.ok) {
      throw new Error('Sorry something went wrong');
    }

    const data = await response?.json();
    console.log('data',data)
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
