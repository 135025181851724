import React from "react";
import "./WhoWeAre.scss";
import {  Divider } from "antd";
import rugbyImg from "../../../assets/images/who-we-image.jpg";

export default function WhoWeAreComponent() {
    const stats = [
        {
            count: "32",
            title: "Campaigns",
        },
        {
            count: "2350",
            title: "Participants",
        },
        {
            count: "$350000",
            title: "Funds Raised",
        },
    ];

    // COMPONENT THAT RENDERS EACH STAT IN THE STATISTICS BOX OVER THE IMAGE
    function singleStat(count, title) {
        return (
            <div className="stat">
                <div className="number">{count}</div>
                <div className="stat-name">{title}</div>
            </div>
        );
    }

    return (
        <>
            <div className="whoWe">
                <div className="heading-component">
                    <div className="title">WHO WE ARE?</div>
                    <div className="subTitle">Empowering US Rugby through Team Fundraising</div>
                </div>
                <div className="content">
                    {/* THE IMAGE AND STATS COMPONENT START HERE */}
                    <div className="left-container">
                        <div className="info-group-entity">
                            <div className="image-container">
                                <img className='rugbyImg' src={rugbyImg} alt="who-we-are-ref-image" />
                            </div>
                            <div className="stats-container">
                                {stats.map((item, i) => {
                                    return (
                                        <>
                                            {singleStat(item.count, item.title)}
                                            {(i !== stats.length - 1) ? (
                                                <Divider className='stats-divider' type='horizontal' dashed />
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {/* IMAGE AND STATS COMPONENT ENDS HERE */}

                    {/* CONTENT COMPONENT STARTS HERE */}
                    <div className="right-container">
                        <div className="content">
                            Welcome to LFT Rugby, your dedicated
                            partner in championing US Rugby teams
                            through innovative fundraising solutions. At
                            LFT Rugby, we are committed to empowering
                            rugby teams across the nation with our easy-
                            to-use and highly effective online fundraising
                            platform. Whether you&#39;re a team looking to
                            raise funds or a supporter wanting to
                            contribute, our platform is designed for your
                            convenience and success.
                            LFT Rugby simplifies the fundraising process,
                            making it accessible and hassle-free for
                            everyone involved. Teams can effortlessly set
                            up their campaigns, while donors can
                            contribute with just a few clicks. Our platform
                            streamlines the fundraising experience,
                            ensuring it is enjoyable and rewarding.
                        </div>
                    </div>
                </div>
                {/* CONTENT COMPONENT ENDS HERE  */}
            </div>

            {/* THE TEAM HUDDLE IMAGE COMPONENT STARTS HERE */}
            <div className="fillerImg-section">
                <div className="section-one">
                    <p>Let's Fundraise</p>
                    <p>Together...</p>
                </div>
                <div className="section-one-subtitle">
                    <p>For the Love of  Rugby</p>
                </div>
                <div className="section-two">
                    <div className="left-container">
                        <span className="span-content"></span>
                    </div>
                    <div className="right-container">
                        <span className="span-content"></span>
                    </div>
                </div>
            </div>
            {/* THE TEAM HUDDLE IMAGE COMPONENT ENDS HERE*/}
        </>
    )
}
