import React, { useEffect, useState } from "react";
import { Card, Row, Col, Image, Button } from "antd";
import "./ProductDetail.scss";
import backButton from "../../../assets/images/back-arrow-product-detail.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Gid } from "../../../Utils/constants";
import { GET_PRODUCT_BY_ID } from "../../../services/ProductDetail/ProductDetail";
import { useQuery } from "@apollo/client";
import Loader from "../../../Components/Loader/Loader";
import Carousel from "../../../Components/Carousel/Carousel";
import ProductDetailCarousel from "./ProductDetailCarousel";
import { formatAmount } from "../../../Utils/constants";

export default function ProductDetail() {
  const { id } = useParams();
  const productId = `${Gid?.productGid}/${id}`;
  const navigate = useNavigate();
  const location = useLocation();
  const referrer = location.state?.referrer;

  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id: productId },
  });
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    if (
      data &&
      data.product &&
      data.product.images &&
      data.product.images.nodes.length > 0
    ) {
      setMainImage(data?.product?.images?.nodes?.[0]?.src);
    }
  }, [data]);

  const navigateBack = () => {
    navigate(referrer || "/");
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <div className="error-container">
      <div>
        {error.message ||
          "Oops! We encountered an issue while loading the Product Details. Please try again later."}
      </div>
      <div className="error-btn-container">
        <Button onClick={() => navigate(-1 || "/")} className="btn">
          Go Back
        </Button>
      </div>
    </div>
  ) : (
    <div className="product-detail-top-container">
      <div className="product-detail-heading-container">
        <img
          src={backButton}
          alt="Back"
          className="product-detail-back-btn"
          onClick={navigateBack}
        />
        <div className="product-detail-title">{data?.product?.title}</div>
      </div>
      <Card className="product-detail-card">
        <Row gutter={[64, 16]} justify="center">
          <Col xs={24} md={12}>
            {mainImage ? (
              <div className="product-detail-main-image-container">
                <Image
                  src={mainImage}
                  className="product-detail-main-image"
                  alt={data?.product?.title}
                />
              </div>
            ) : (
              <Loader />
            )}
            <div>
              <Carousel
                data={data?.product?.images?.nodes}
                Component={ProductDetailCarousel}
                ComponentName="ProductDetailCarousel"
                setMainImage={setMainImage}
              />
            </div>
          </Col>
          <Col xs={24} md={12} className="Product-detail-desc-container">
            <div className="product-detail-desc-title">
              {data?.product?.title}
            </div>
            <div className="product-detail-desc">
              {data?.product?.description}
            </div>
            <div className="product-detail-price">
              {formatAmount(data?.product?.priceRange?.maxVariantPrice?.amount)}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
