import React, { useEffect, useState } from "react";
import "./ViewCart.scss";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Input } from "antd";
import {
  GET_CART_ITEMS,
  UPDATE_CART_LINES,
  DELETE_CART_LINE,
  CHECKOUT,
  GET_CAMPAIGN_IMAGE_BY_ID,
} from "../../services/ViewQueries";
import { toast } from "react-toastify";
import {
  MinusOutlined,
  PlusOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import Loader from "../../Components/Loader/Loader";
import { formatAmount } from "../../Utils/constants";
import DeleteAlertModal from "../../Components/DeleteAlertModal/DeleteAlertModal";
import DeleteIcon from "../../assets/images/delete-icon.svg";
function ViewCart() {
  const [cartLinesUpdate] = useMutation(UPDATE_CART_LINES);
  const [cartLineDelete] = useMutation(DELETE_CART_LINE);
  const [viewCartProducts, setViewCartPRoducts] = useState([]);
  const [estimatedTotal, setEstimatedTotal] = useState("");
  const [checkoutUrl, setCheckoutUrl] = useState();
  const cartId = localStorage.getItem("cartId");
  const [productValue,setProductValue] = useState('0')
  const [showDeleteModal, setShowDeleteModal] = useState({
    item: "",
    index: "",
    delete: false,
  });
  const [checkoutDisable, setCheckoutDisable] = useState(false);

  const navigate = useNavigate();
  const {
    loading,
    error,
    data: cartResp,
    refetch,
  } = useQuery(GET_CART_ITEMS, {
    variables: {
      id: cartId,
    },
  });
  const { data: campaignDetailsData } = useQuery(GET_CAMPAIGN_IMAGE_BY_ID, {
    variables: {
      id: `${cartResp?.cart?.lines?.edges[0]?.node?.attributes[0]?.value}`,
    },
  });
  const {
    loading: load,
    error: err,
    data: checkoutData,
    refetch: checkoutRefetch,
  } = useQuery(CHECKOUT, {
    variables: {
      cartId: cartId,
    },
  });
  useEffect(() => {
    /*we are saving checkout url so that once user click on checkout button will navigate to new tab" */
    if (checkoutData) {
      setCheckoutUrl(checkoutData?.cart?.checkoutUrl);
    }
  }, [checkoutData, campaignDetailsData]);

  /* Fetching view cart list from storefrontQl and rearranging the data how we need it */
  const fetchData = async () => {
    const { data } = await refetch();
    if (data) {
      const newResult = data?.cart?.lines?.edges?.map((item) => ({
        ...item,
        image: item?.node?.merchandise?.product?.featuredImage?.src || "",
        productName: item?.node?.merchandise?.product?.title,
        price: item?.node?.merchandise?.price?.amount,
        key: item?.node?.attributes[0]?.key,
        value: item?.node?.attributes[0]?.value,
        subtotal: item?.node?.cost?.subtotalAmount?.amount,
        title: item?.node?.merchandise?.product?.title,
        quantity: item?.node?.quantity,
        cartId: data?.cart?.id,
      }));
      setEstimatedTotal(data?.cart?.cost?.totalAmount?.amount);
      setViewCartPRoducts(newResult);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  /* Updating cart Quantity */
  const updateCartLines = async (cartLine, index, type, value = 0) => {
    setCheckoutDisable(true)
    let copyOfViewProducts = viewCartProducts;
    const productValue = isNaN(value) ? "" : value;
    copyOfViewProducts[index] = {
      ...copyOfViewProducts[index],
      quantity:
        type === "add"
          ? copyOfViewProducts[index]?.quantity + 1
          : type === "update"
          ? (copyOfViewProducts[index].quantity = productValue)
          : copyOfViewProducts[index]?.quantity - 1,

      loading: true,
    };
    setViewCartPRoducts(copyOfViewProducts);

    const lines = [
      {
        attributes: [
          {
            key: cartLine?.key,
            value: cartLine?.value,
          },
        ],
        id: cartLine?.node?.id,
        merchandiseId: cartLine?.node?.merchandise?.id,
        quantity:
          copyOfViewProducts[index]?.quantity === ""
            ? 0
            : copyOfViewProducts[index]?.quantity,
      },
    ];
    try {
      const { data } = await cartLinesUpdate({
        variables: {
          cartId: cartLine?.cartId,
          lines,
        },
      });

      let copyOfUpdateProducts = viewCartProducts;
      copyOfUpdateProducts[index] = {
        ...copyOfUpdateProducts[index],
        subtotal:
          data?.cartLinesUpdate?.cart?.lines?.edges[index]?.node?.cost
            ?.subtotalAmount?.amount,
        loading: false,
      };
      setViewCartPRoducts(copyOfUpdateProducts);
      setEstimatedTotal(data?.cartLinesUpdate?.cart?.cost?.totalAmount?.amount);
      setCheckoutDisable(false)
    } catch (err) {
      copyOfViewProducts[index] = {
        ...copyOfViewProducts[index],
        loading: false,
      };
      toast.error("Sorry something went wrong while updating ");
      setCheckoutDisable(false)
    }
  };

  /*Deleting of cart*/
  const deleteCartLine = async (cartDeleteItem, index, toastVisible = true) => {
    try {
      const { data } = await cartLineDelete({
        variables: {
          cartId: cartDeleteItem?.cartId,
          lineIds: [cartDeleteItem?.node?.id],
        },
      });
      if (data?.cartLinesRemove?.cart?.id) {
        if (toastVisible) {
          toast.success("Deleted successfully");
          setShowDeleteModal("");
        }

        let filterArray = viewCartProducts;
        filterArray = filterArray.filter(
          (item, filterIndex) => index !== filterIndex
        );
        setViewCartPRoducts(filterArray);

        if (!filterArray?.length) {
          localStorage.clear();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    } catch (Error) {
      toast.error("Sorry delete failed");
    }
  };

  const onCheckoutClick = async () => {
    window.open(checkoutUrl, "_self");
  };

  const handleChange = (item, index, type, e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      console.log('inside id');
      const numValue = parseInt(inputValue, 10);
      if (numValue > 0 || inputValue === "") {
        console.log('lin2 200');
        const quantityValue = isNaN(numValue) ? "" : numValue;
        setProductValue(quantityValue)
        if (quantityValue === "") {
          console.log('line 204',quantityValue);
          let copyOfViewProducts = viewCartProducts;
          copyOfViewProducts[index] = {
            ...copyOfViewProducts[index],
            quantity: quantityValue,
            loading: true,
          };
          setViewCartPRoducts(copyOfViewProducts);
        } else {
          updateCartLines(item, index, type, numValue);
        }
      }
    } else {
      console.log('inside else');
    } 
  };

  console.log("carviewProducttttt", viewCartProducts);

  return (
    <div className="view-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          {viewCartProducts?.length ? (
            <>
              <div className="checkout-container">
                <h1 className="your-cart">Your cart</h1>
              </div>
              <div className="view-cart-container">
                <div className="cart-header-wrapper">
                  <div className="empty-container"></div>
                  <h2 className="label">Quantity</h2>
                  <h2 className="label">Price</h2>
                </div>

                <div>
                  {viewCartProducts.map((item, index) => (
                    <div className="cart-content">
                      <div className="product-info">
                        <div className="product-img-container">
                          <img
                            src={item?.image}
                            alt={item?.productName}
                            className="product-image"
                          />
                        </div>
                        <div className="product-name-price">
                          <p className="product-name">{item?.productName}</p>
                          <p className="price">{formatAmount(item?.price)}</p>
                        </div>
                      </div>
                      <div className="product-quantity-price">
                        <div className="quantity-container">
                          <div className="quantity-border">
                            <MinusOutlined
                              className={`button ${
                                item.quantity === 1 ? "disabled" : ""
                              }`}
                              onClick={() => {
                                if (item?.quantity === 1) {
                                  return;
                                }
                                updateCartLines(item, index, "remove");
                              }}
                            />
                            <input
                            className="quantity-input-box"
                              type="text"
                              value={item?.quantity}
                              onChange={(e) => {
                                handleChange(item, index, "update", e);
                              }}
                             
                            />
                            <PlusOutlined
                              className="button"
                              onClick={() => {
                                updateCartLines(item, index, "add");
                              }}
                            />
                          </div>
                          <div className="delete-icon">
                            <img
                              className="cancel"
                              onClick={() => {
                                setShowDeleteModal(item);
                                setShowDeleteModal((prev) => ({
                                  ...prev,
                                  item: item,
                                  index: index,
                                  delete: true,
                                }));
                              }}
                              src={DeleteIcon}
                              alt="remove"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="price-container">
                        <p className="sub-total">
                          {" "}
                          {item?.loading ? (
                            <div>
                              <Loader
                                calculateHeight={false}
                                customWidth={40}
                              />
                            </div>
                          ) : (
                            <>{formatAmount(item?.subtotal)}</>
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="view-cart-footer">
                <div className="estimated-container">
                  <p className="estimated-total">
                    {" "}
                    <span>Estimated Total </span>
                  </p>
                  <p className="estimated-total-price">
                    {formatAmount(estimatedTotal)}
                  </p>
                </div>
                <div className="tax">
                  Taxes, Discount and Shipping calculated at Checkout
                </div>
                <Button
                  onClick={() => {
                    onCheckoutClick();
                  }}
                  className="checkout-button"
                  disabled={checkoutDisable}
                >
                  Checkout
                </Button>

                {showDeleteModal?.delete && (
                  <DeleteAlertModal
                    openModal={true}
                    closeModal={() => {
                      setShowDeleteModal("");
                    }}
                    handleYes={() => {
                      deleteCartLine(
                        showDeleteModal?.item,
                        showDeleteModal?.index
                      );
                    }}
                    title={showDeleteModal?.item?.productName}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="empty-cart">
              <h2>Your cart is Empty</h2>
              <p>
                Please click{" "}
                <span
                  style={{ cursor: "pointer" }}
                  className="navigate-link"
                  onClick={() => {
                    navigate("/fundraisers");
                  }}
                >
                  fundraisers
                </span>{" "}
                to add some products into cart
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ViewCart;
