import React from "react";
import "./Fundraiser.scss";
import { Button } from "antd";
import fundraiserProcessImage from "../../../assets/images/fundraiser_process_image_test.JPG";
import beekeperImage from "../../../assets/images/beekeper.jpg";
import honeyPotImage from "../../../assets/images/honey-pot.jpg";

export default function FundraiserInfoComponent() {
  const windowWidth = window?.innerWidth;
  return (
    <div className="fundraiser">
      <div className="fundraiser-page-heading">
        <div className="title">Kicking the Rugby Fundraiser Goal in 3 steps!</div>
      </div>
      <div className="content">
        <div className="image-container">
          <img className="fh-process-image" src={fundraiserProcessImage} alt="" />
        </div>
        <div className="content-container">
          <p>Experience the simplicity of rugby fundraising with our three- step process.</p>
          <p className="sub-headers">Step 1</p>
          <p>Begins with a personalized consultation with one
            of our LFT Rugby Team members to
            outline your team's fundraising
            objectives.This takes 20 minutes!</p>
          <p className="sub-headers">Step 2</p>
          <p>Your fundraising team is
            seamlessly integrated into the LFT
            Rugby platform, where each player
            receives a unique link.This link
            empowers them to effortlessly share
            a link to our curated selection of
            premium natural Mānuka Honey
            products, health essentials, and
            luxurious skincare from New
            Zealand with their networks of
            friends, families and supporters
            across the country.</p>
          <p className="sub-headers">Step 3</p>
          <p>Brings it all together: Your
            team earns a 20% commission on all
            sales generated through their
            unique links.Once the fundraiser
            concludes, this commission is
            promptly disbursed, ensuring a
            successful and rewarding
            fundraising endeavor for your rugby
            team.</p>
        </div>
      </div >
      <div className="fundraiser-page-heading second-header">
        <div className="title">New Zealand Mānuka honey is a top choice for health benefits.</div>
      </div>
      <div className="content second-content">
        <div className="image-container">
          <img className="base-img" src={beekeperImage} alt="beekeeper" />
          <img className="top-img" src={honeyPotImage} alt="honey pot" />
        </div>
        <div className="content-container">
          <p>New Zealand’s Mānuka Honey,
            renowned for its potent
            antibacterial properties, aids in
            wound healing, immune support,
            and digestive health.</p>
          <br />
          <p>This natural
            wonder is complemented by a
            range of Mānuka- infused products,
            including gummies and skincare
            items.Mānuka honey gummies
            provide a convenient way to boost
            energy and wellness, while Mānuka
            skincare products offer deep
            hydration and natural antibacterial
            benefits, promoting healthy,
            glowing skin.
          </p>
          <br />
          <p>At LFT Rugby, we are
            committed to bringing these natural
            products to you, providing a holistic
            approach to enhancing vitality,
            supporting overall health, and
            fostering natural healing.</p>
          <a href="https://www.umf.org.nz/nz-manuka-honey/" target="_blank" rel="noreferrer">
            <Button className="readmore-btn">
              {windowWidth >= 768 ?
                <span className="btn-text">Find out more about the health benefits of Mānuka Honey</span>
                :
                <span className="btn-text">Know more about Mānuka Honey</span>
              }
            </Button>
          </a>
        </div>
      </div>
    </div >
  );
}