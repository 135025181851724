import React, { useState, useEffect } from "react";
import "./MiniCart.scss";
import { useQuery, useMutation } from "@apollo/client";
import { hideMiniCart } from "../../Reducers/miniCartSlice";
import {
  GET_CART_ITEMS,
  UPDATE_CART_LINES,
  CHECKOUT,
} from "../../services/ViewQueries";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../Utils/constants";

function MiniCart() {
  const cartId = localStorage.getItem("cartId");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_CART_ITEMS, {
    variables: {
      id: cartId,
    },
  });
  const [cartLinesUpdate] = useMutation(UPDATE_CART_LINES);
  const [estimatedTotal, setEstimatedTotal] = useState("");
  const [checkoutUrl, setCheckoutUrl] = useState();
  const [loader, setLoader] = useState(false);

  const {
    loading: load,
    error: err,
    data: checkoutData,
  } = useQuery(CHECKOUT, {
    variables: {
      cartId: cartId,
    },
  });

  const [viewCartProducts, setViewCartPRoducts] = useState();

  useEffect(() => {
    /*we are saving checkout url so that once user click on checkout button will navigate to new tab" */
    if (checkoutData) {
      setCheckoutUrl(checkoutData?.cart?.checkoutUrl);
    }
  }, [checkoutData]);

  useEffect(() => {
    if (data) {
      const miniCart = data?.cart?.lines?.edges?.map((item) => ({
        ...item,
        image: item?.node?.merchandise?.product?.featuredImage?.src || "",
        productName: item?.node?.merchandise?.product?.title,
        price: item?.node?.merchandise?.price?.amount,
        key: item?.node?.attributes[0]?.key,
        value: item?.node?.attributes[0]?.value,
        subtotal: item?.node?.cost?.subtotalAmount?.amount,
        title: item?.node?.merchandise?.product?.title,
        quantity: item?.node?.quantity,
        cartId: data?.cart?.id,
      }));
      setEstimatedTotal(data?.cart?.cost?.totalAmount?.amount);
      setViewCartPRoducts(miniCart);
    }
  }, [data]);

  const updateCartLines = async (cartLine, index, type) => {
    setLoader(true);
    let copyOfViewProducts = viewCartProducts;

    copyOfViewProducts[index] = {
      ...copyOfViewProducts[index],
      quantity:
        type === "add"
          ? copyOfViewProducts[index]?.quantity + 1
          : copyOfViewProducts[index]?.quantity - 1,
      loading: true,
    };

    setViewCartPRoducts(copyOfViewProducts);

    const lines = [
      {
        attributes: [
          {
            key: cartLine?.key,
            value: cartLine?.value,
          },
        ],
        id: cartLine?.node?.id,
        merchandiseId: cartLine?.node?.merchandise?.id,
        quantity: copyOfViewProducts[index]?.quantity,
      },
    ];
    try {
      const { data } = await cartLinesUpdate({
        variables: {
          cartId: cartLine?.cartId,
          lines,
        },
      });

      let copyOfUpdateProducts = viewCartProducts;
      copyOfUpdateProducts[index] = {
        ...copyOfUpdateProducts[index],
        subtotal:
          data?.cartLinesUpdate?.cart?.lines?.edges[index]?.node?.cost
            ?.subtotalAmount?.amount,
        loading: false,
      };
      setLoader(false);
      setViewCartPRoducts(copyOfUpdateProducts);
      const total = viewCartProducts?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.subtotal;
      }, 0);
      setEstimatedTotal(data?.cartLinesUpdate?.cart?.cost?.totalAmount?.amount);
    } catch (err) {
      copyOfViewProducts[index] = {
        ...copyOfViewProducts[index],
        loading: false,
      };
      setLoader(false);
      toast.error("Sorry something went wrong while updating ");
    }
  };

  const onCheckoutClick = async () => {
    window.open(checkoutUrl, "_self");
    dispatch(hideMiniCart());
  };

  return (
    <div className="mini-cart-wrapper">
      <div className="mini-cart-box">
        {loading ? (
          <div className="loading">
            <Loader />
          </div>
        ) : (
          <div className="mini-cart-container">
            <div className="mini-cart-order-wrapper">
              <span className="campaign">Cart</span>

              <div className="orders-container">
                {viewCartProducts?.map((item, index) => (
                  <div key={index} className="order-row">
                    <div>
                      <img
                        className="productImage"
                        src={item?.image || ""}
                        alt={item?.productName}
                        width={50}
                        height={60}
                      />
                    </div>
                    <div className="mini-cart-product-details">
                      <div>
                        <p className="product-name">{item?.productName}</p>
                      </div>
                      <div className="quantity-wrapper">
                        <span className="price">
                          {" "}
                          {item?.loading ? (
                            <div>
                              <Loader
                                calculateHeight={false}
                                customWidth={25}
                              />
                            </div>
                          ) : (
                            <>
                              {item?.subtotal > 0
                                ? `${formatAmount(item.subtotal)}`
                                : ""}
                            </>
                          )}
                        </span>
                        <div className="quantity-button-container">
                          <div
                            className={`button ${
                              item?.quantity === 1 ? "cursor-not-allowed" : ""
                            } remove-button `}
                            onClick={(event) => {
                              if (item?.quantity === 1) return;
                              updateCartLines(item, index, "remove");
                              event?.stopPropagation();
                            }}
                          >
                            -
                          </div>

                          <span className="quantity">{item?.quantity}</span>
                          <div
                            className="button add-button"
                            onClick={(event) => {
                              updateCartLines(item, index, "add");
                              event?.stopPropagation();
                            }}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="footer-wrapper">
                <div className="footer-container">
                  <p className="footer-font">Total Amount</p>
                  <p className="footer-font">
                    {" "}
                    {loader ? (
                      <Loader calculateHeight={false} customWidth={40} />
                    ) : (
                      `${formatAmount(estimatedTotal)}`
                    )}{" "}
                  </p>
                </div>

                <div
                  className="footer-container view-cart"
                  onClick={() => {
                    navigate("/view-cart");
                    dispatch(hideMiniCart());
                  }}
                >
                  <p className="footer-font">View Cart</p>
                </div>

                <div
                  className="footer-container checkout"
                  onClick={() => {
                    onCheckoutClick();
                  }}
                >
                  <p className="footer-font checkout-underline">CHECKOUT</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MiniCart;
