import { createSlice } from "@reduxjs/toolkit";

const miniCartSlice = createSlice({
  name: "cart",
  initialState: {
    showMiniCart: false,
    getCampaignFlag: false,
    whoWeAreClicked: false,
  },

  reducers: {
    showMiniCart: (state) => {
      state.showMiniCart = true;
    },
    hideMiniCart: (state) => {
      state.showMiniCart = false;
    },
   

    whoWeAreClick: (state, action) => {
      state.whoWeAreClicked = action.payload;
    },
  },
});

export const { showMiniCart, hideMiniCart,whoWeAreClick } =
  miniCartSlice.actions;
export default miniCartSlice.reducer;
