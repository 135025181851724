import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Campaigns.scss";
import { Button } from "antd";
import arrowIcon from "../../../assets/images/arrow-icon.png";
import LandingCampaigns from "../../../Components/Campaigns/LandingCampaigns";
import Carousel from "../../../Components/Carousel/Carousel";
import { fetchCampaigns } from "../../../Reducers/campaignsSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader/Loader";
import ListingCampaigns from "../../../Components/Campaigns/ListingCampaigns";
import { whoWeAreClick } from "../../../Reducers/miniCartSlice";
import * as Scroll from "react-scroll";

export default function CampaignsComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const whoWeAreClicked = useSelector(
    (state) => state.miniCart?.whoWeAreClicked
  );
  const scroller = Scroll.scroller;
  const windowWidth = window.innerWidth;

  const { campaigns, loading, error, success } = useSelector(
    (state) => state.campaigns
  );

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  const scrollToWhoWeAre = async () => {
    console.log("i am here");
    await scroller.scrollTo("who-we-are", {
      duration: 1,
      smooth: true,
      isDynamic: true,
      offset: windowWidth <= 768 ? -50 : -50,
    });
    dispatch(whoWeAreClick(false));
  };

  useEffect(() => {
    if (success === "success" && whoWeAreClicked) {
      scrollToWhoWeAre();
    }
  }, [success, whoWeAreClicked]);



  return (
    <div>
      {loading ? (
        <Loader />
      ) : error || !campaigns || !campaigns.length ? (
        <></>
      ) : (
        <div className="campaigns-component">
          <div className="heading-component">
            <div className="heading-left-container">
              <div className="orange-heading">BE PART OF THE TEAM!</div>
              <div className="main-heading">
                SUPPORT ACTIVE RUGBY FUNDRAISERS
              </div>
            </div>
            <div className="heading-right-container">
              <div className="heading-content">
                Be part of the team! By supporting our active rugby fundraisers,
                you’re investing in our players and their goals, whether it’s
                attending tournaments, getting equipment, or other needs. Your
                generosity helps provide vital resources and opportunities. Help
                us strengthen and grow rugby in the US!
              </div>
              <Button
                className="discover-btn"
                onClick={() => navigate("/fundraisers")}
              >
                <span>Discover All</span>
                <img
                  className="arrow-icon"
                  src={arrowIcon}
                  alt="Discover more"
                />
              </Button>
            </div>
          </div>
          {campaigns.length === 1 ? (
            <ListingCampaigns index={0} campaign={campaigns[0]} home={true} />
          ) : (
            <div className="campaigns-carousel">
              <Carousel
                data={campaigns}
                Component={LandingCampaigns}
                ComponentName="LandingCampaigns"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
