const API_URL = process.env.REACT_APP_API_BASE_URL;

export const getOrderSummaryById = async (orderId) => {
  try {
    const response = await fetch(
      `${API_URL}/public/order/${orderId}`
    );

    if (!response.ok) {
      throw new Error('Error Fetching Fundraiser Details');
    }

    const data = await response.json();
    console.log('data',data)
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

