
export const clearLocalStorage = () => {
 
  return new Promise((resolve, reject) => {
    try {
      localStorage.clear();
      resolve("localStorage storage cleared");
    } catch (error) {
      reject("Failed to clear localStorage storage");
    }
  });
};

export const  getLocalStorageItem = (key) => {
    console.log('key',key)
    return new Promise((resolve, reject) => {
        try {
            const value = localStorage.getItem(key);
            if (value !== null) {
                resolve(value);
            } else {
                reject(`No item found in local storage for key: ${key}`);
            }
        } catch (error) {
            reject(`Failed to retrieve item from local storage storage: ${error}`);
        }
    });
};

export const setLocalStorageStorageItem = (key, value) => {
    return new Promise((resolve, reject) => {
        try {
            sessionStorage.setItem(key, value);
            resolve("Success");
        } catch (error) {
            reject(`Failed to retrieve item from local storage: ${error}`);
        }
    });
};


