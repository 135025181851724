import React from "react";
import { Button } from "antd";
import "../Campaigns/LandingCampaigns.scss";
import { useNavigate } from "react-router-dom";

function LandingCampaigns(cardItem) {
  const navigate = useNavigate();

  // REDIRECT USER TO CAMPAIGN DETAILS PAGE
  const handleRedirect = (id) => {
    // RETIREVE THE CAMPAIGN ID FROM GUID URL
    const campaignID = id.match(/\d+$/)[0];
    navigate(`/fundraisers-details/${campaignID}`);
  };

  const formatDate = (inputDate) => {
    // Pass the input date
    const date = new Date(inputDate);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });

    // Retrieve the correct suffix for the day
    let daySuffix;
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = "st";
    } else if (day === 2 || day === 22) {
      daySuffix = "nd";
    } else if (day === 3 || day === 23) {
      daySuffix = "rd";
    } else {
      daySuffix = "th";
    }

    // Format the date in the desired format
    return `${day}${daySuffix} ${month}`;
  };

  if (cardItem) {
    return (
      <div className="main-container-landing">
        <div className="image-container-landing">
          <img
            src={cardItem?.data?.carousel_imageUrl || cardItem?.data?.imageUrl}
            alt={cardItem?.data?.title}
            className="card-image-landing"
          />
          <div className="date-landing">
            {/* ENDING DATE STRING IN THE CARD */}
            <div className="date">Ending</div>
            <div className="date">{formatDate(cardItem?.data?.end_date)}</div>
          </div>
        </div>
        <div className="card-description-landing">
          <div className="desc-content">
            <div className="card-header">
              <h2 className="card-heading-landing">{cardItem?.data?.title}</h2>
            </div>
            <div className="card-desc">
              <div className="card-details-landing">
                {cardItem?.data?.description}
              </div>
            </div>
          </div>
          <div className="btn-container">
            <Button
              className="view-campaigns-button"
              onClick={() => handleRedirect(cardItem?.data?.id)}
            >
              View Fundraiser
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingCampaigns;
