import React, { useState } from "react";
import "./Header.scss";
import { Divider, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import LFTLogo from "../../assets/images/LFT_RUGBY.svg";
import cartIcon from "../../assets/images/Cart-Icon.svg";
import menuIcon from "../../assets/images/menu-icon.svg";
import MiniCart from "../Minicart/MiniCart";
import { useSelector, useDispatch } from "react-redux";
import {  hideMiniCart,whoWeAreClick } from "../../Reducers/miniCartSlice";
import useOutsideClick from "../../Utils/OutsideClick";


function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPages, setShowPages] = useState(false);
  const showMiniCart = useSelector((state) => state?.miniCart?.showMiniCart);
  const windowWidth = window.innerWidth;


  const showPagesFunc = () => {
    setShowPages(!showPages);
  };


  const pages = [
    {
      page: "WHO WE ARE",
      route: "who-we-are",
    },
    {
      page: "FUNDRAISERS",
      route: "/fundraisers",
    },
    {
      page: "CONTACT US",
      route: "/contact-us",
    },
  ];



  const handleNavigation = async (route) => {
    if (route === "who-we-are") {
      await navigate("/");
      dispatch(whoWeAreClick(true))
      
    } else {
      dispatch(whoWeAreClick(false))
      navigate(route);
    }
  };

  const hideCart = () => {
    dispatch(hideMiniCart());
  };
  const divRef = useOutsideClick(hideCart);


  return (
    <>
      <div className="header">
        <div className="logo">
          <img
            className="logo-img"
            src={LFTLogo}
            onClick={() => {
              navigate("/");
            }}
            alt="LFT logo"
          />
          {showMiniCart && (
            <div ref={divRef}>
              <MiniCart />
            </div>
          )}
        </div>
        {/* SHOW THE PAGES IN THE HEADER ONLY WHEN THE SCREENSIZE IS ABOVE 768PX, ELSE SHOW THE MENU ICON WHICH WILL RENDER THE POPOVER COMPONENT FURTHER DOWN  */}
        {windowWidth >= 768 ? (
          <div className="pages">
            {pages.map((page) => {
              return (
                <>
                  <p
                    className="page"
                    onClick={() => {
                      handleNavigation(page.route);
                    }}
                  >
                    {page.page}
                  </p>
                </>
              );
            })}
          </div>
        ) : (
          <></>
        )}
        <div className="roleOptions">
          <div
            className="userAction"
            onClick={() => {
              navigate("/view-cart");
            }}
          >
            <img className="action-icon" src={cartIcon} alt="Cart" />
            <p>CART</p>
          </div>
          {/* POPOVER COMPONENT STARTS HERE */}
          {windowWidth <= 768 ? (
            <Popover
              content={
                <div className="popover-pages">
                  {pages.map((page, i) => {
                    return (
                      <>
                        {page?.route === "who-we-are" ? (
                          <p onClick={() => { handleNavigation(page?.route)
                            setShowPages(false);
                           }}>{page?.page}</p>
                        ) : (
                          <p
                            className="page"
                            onClick={() => {
                              navigate(page.route);
                              setShowPages(false);
                            }}
                          >
                            {page.page}
                          </p>
                        )}

                        {i !== pages?.length - 1 ? (
                          <Divider
                            type="horizontal"
                            className="popover-divider"
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </div>
              }
              trigger="click"
              arrow={false}
              open={showPages}
              onOpenChange={showPagesFunc}
              placement="bottomRight"
            >
              <div
                className="userAction"
                onClick={() => setShowPages(!showPages)}
              >
                <img className="action-icon" src={menuIcon} alt="Cart" />
              </div>
            </Popover>
          ) : (
            <></>
          )}
          {/* POPOVER COMPONENT ENDS HERE */}
        </div>
      </div>
    </>
  );
}

export default Header;
