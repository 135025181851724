import React from "react";
import { Modal, Button } from "antd";
import "./DeleteAlertModal.scss";

function DeleteAlertModal({ openModal,
    closeModal,
    handleYes,
    title,}) {
  return (
    <>
    <Modal
      className="confirmation-modal"
      open={openModal}
      footer={null}
      onOk={handleYes}
      onCancel={() => closeModal(false)}
      destroyOnClose={true}
      closable={false}
    >
      <div className="message">
        <p>{`Are you sure you want to delete this ${title}?`}</p>
      </div>
      <div className="modalBtns">
        <Button
          label="No"
          className="cancel-btn"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button label="Yes" className="confirm-btn" onClick={handleYes}>
          Yes
        </Button>
      </div>
    </Modal>
  </>
  )
}

export default DeleteAlertModal