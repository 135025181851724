import { Image } from "antd";
import React from "react";
import "./ProductDetailCarousel.scss";

export default function ProductDetailCarousel({ data, setMainImage }) {
  return (
    <div className="product-detail-carousel-container">
      <Image
        src={data?.originalSrc}
        onClick={() => setMainImage(data?.originalSrc)}
        className="product-detail-image"
        preview={false}
      />
    </div>
  );
}
