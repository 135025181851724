import { gql } from "@apollo/client";

export const GET_CART_ITEMS = gql`
  query cart($id:ID!) {
    cart(
      id: $id
    ) 
   {
      id
      createdAt
      updatedAt 
      lines(first: 10) {
        edges {
          node {
            id
            cost {
              amountPerQuantity {
                amount
              }
              subtotalAmount {
                amount
              }
              totalAmount {
                amount
              }
            }
            quantity
            merchandise {
              ... on ProductVariant {
                id
                price {
                  amount
                }
                product {
                  createdAt
                  featuredImage {
                    src
                  }
                  title
                  tags
                }
              }
            }

            attributes {
              key
              value
            }
          }
        }
      }
      totalQuantity
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
      buyerIdentity {
        email
        phone
        customer {
          id
        }
        countryCode
        deliveryAddressPreferences {
          ... on MailingAddress {
            address1
            address2
            city
            provinceCode
            countryCodeV2
            zip
          }
        }
      }
    }
  }
`;
export const UPDATE_CART_LINES = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              cost {
                amountPerQuantity {
                  amount
                }
                subtotalAmount {
                  amount
                }
                totalAmount {
                  amount
                }
              }
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount
                  }
                  product {
                    createdAt
                    featuredImage {
                      src
                    }
                    title
                    tags
                  }
                }
              }

              attributes {
                key
                value
              }
            }
          }
        }
        totalQuantity
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
        buyerIdentity {
          email
          phone
          customer {
            id
          }
          countryCode
          deliveryAddressPreferences {
            ... on MailingAddress {
              address1
              address2
              city
              provinceCode
              countryCodeV2
              zip
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CART_LINE = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              cost {
                amountPerQuantity {
                  amount
                }
                subtotalAmount {
                  amount
                }
                totalAmount {
                  amount
                }
              }
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  price {
                    amount
                  }
                  product {
                    createdAt
                    featuredImage {
                      src
                    }
                    title
                    tags
                  }
                }
              }

              attributes {
                key
                value
              }
            }
          }
        }
        totalQuantity
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
        buyerIdentity {
          email
          phone
          customer {
            id
          }
          countryCode
          deliveryAddressPreferences {
            ... on MailingAddress {
              address1
              address2
              city
              provinceCode
              countryCodeV2
              zip
            }
          }
        }
      }
    }
  }
`;

export const CHECKOUT = gql`
query checkoutURL($cartId: ID!) {
    cart(id: $cartId) {
      checkoutUrl
    }
  }
`;

export const GET_CAMPAIGN_IMAGE_BY_ID = gql`
  query GetCampaignById($id: ID!) {
    metaobject(id: $id) {
      type
      handle

      ImageReference: field(key: "image") {
        reference {
          ... on MediaImage {
            image {
              originalSrc
            }
          }
        }
      }
    }
  }
`;
