import React, { useEffect, useState } from "react";
import "../Campaigns/CampaignDetailsPage.scss";
import { Button, Modal } from "antd";
import { LinkOutlined, ShareAltOutlined } from "@ant-design/icons";
import Product from "../../../Components/Campaigns/Product";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Carousel from "../../../Components/Carousel/Carousel";
import { Gid } from "../../../Utils/constants";
import Loader from "../../../Components/Loader/Loader";
import { fetchCampaignDetail } from "../../../services/CampaignDetail/CampaignDetail";
import { RWebShare } from "react-web-share";
import { Helmet } from "react-helmet";
import heroImg from "../../../assets/images/hero-img.jpg";

function CampaignDetailsPage() {
  const { id } = useParams();
  const location = useLocation();
  const [campaignData, setCampaignData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const campaignID = `${Gid?.metaobjectGid}/${id}`;
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.search?.includes("showPopup=true")) {
      setShowSharePopup(true);
      const tempParam = location?.search?.replace("?showPopup=true", "");
      window.history.replaceState(
        {},
        "",
        `${location.pathname}${tempParam.toString()}`
      );
    }
  }, []);

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCampaignDetail(campaignID);
        setCampaignData(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [campaignID]);

  const startDate = new Date(campaignData?.start_date);
  const endDate = new Date(campaignData?.end_date);

  // Format start date
  const formattedStartDate =
    startDate.getDate() +
    getOrdinalSuffix(startDate.getDate()) +
    " " +
    startDate.toLocaleString("en-US", { month: "short" });

  // Format end date
  const formattedEndDate =
    endDate.getDate() +
    getOrdinalSuffix(endDate.getDate()) +
    " " +
    endDate.toLocaleString("en-US", { month: "short" });

  // Combine formatted dates
  const formattedDateRange = `${formattedStartDate} to ${formattedEndDate}`;

  return loading ? (
    <Loader />
  ) : error ? (
    <div className="error-container">
      <div>
        {error.message ||
          "Oops! We encountered an issue while loading the fundraisers Details. Please try again later."}
      </div>
      <div className="error-btn-container">
        <Button onClick={() => navigate("/fundraisers")} className="btn">
          Go Back
        </Button>
      </div>
    </div>
  ) : (
    <div>
      <Helmet>
        <meta
          property="og:title"
          content={campaignData?.title || "Lets Fundraise Together!"}
        />
        <meta
          property="og:description"
          content={
            campaignData?.short_description ||
            "Be part of the team! Your generosity helps provide vital resources and opportunities. Help us strengthen and grow rugby in the US!"
          }
        />
        <meta property="og:image" content={campaignData?.imageUrl || heroImg} />
      </Helmet>
      {/* banner Image */}
      <div className="hero-container">
        <div
          className="hero"
          style={{
            backgroundImage: `url(${campaignData?.imageUrl})`,
          }}
        ></div>
      </div>
      {/* Orange Bar Heading Container */}
      <div className="heading-container-details">
        <h3 className="heading-details">
          {campaignData?.location} -{formattedDateRange}
        </h3>
      </div>
      {/* Description */}
      <div className="description">
        <div className="top-description">
          <div className="desc-start-heading">WE NEED YOUR HELP</div>
          <div className="desc-heading-details">
            {campaignData?.title?.toUpperCase()}
          </div>
          <div className="desc-sub-heading-details">
            {campaignData?.short_description}
          </div>
          <div className="btn-container">
            {location?.search.length > 0 && (
              <RWebShare
                data={{
                  url:
                    location.pathname +
                    location.search.replace("?showPopup=true", ""),
                }}
              >
                <Button className="share-btn">
                  {window?.innerWidth >= 1024 ? (
                    `Share Fundraiser`
                  ) : (
                    <ShareAltOutlined />
                  )}
                </Button>
              </RWebShare>
            )}
          </div>
        </div>
        <div>
          {campaignData?.team_imageUrl && (
            <img
              className="team-img"
              src={campaignData?.team_imageUrl}
              alt="Team"
            />
          )}
        </div>
        <div>
          <div className="detail-desc">{campaignData?.description}</div>
        </div>
      </div>
      {/* Product Coursel */}
      <div className="main-product-container">
        <div className="view-products-heading">PRODUCTS</div>
        <div className="sub-product-container">
          <Carousel
            className="carousel"
            data={campaignData?.products}
            Component={Product}
            ComponentName="Product"
            startDate={campaignData?.start_date}
            endDate={campaignData?.end_date}
          />
        </div>
      </div>
      <Modal
        className="share-modal"
        open={showSharePopup}
        onCancel={() => setShowSharePopup(false)}
        closeIcon={false}
        closable={true}
        footer={false}
      >
        <div className="share-container">
          <div className="share-header">Share Fundraiser</div>
          <div className="share-msg-container">
            <div className="fundraiser-name">
              {campaignData?.title?.toUpperCase()}
            </div>
          </div>
          <div className="share-action-container">
            <div className="share-msg">
              Share this link below to your family friends and get their support
              behind your fundraising campaign
            </div>
            <div className="share-icon">
              <p>Share Link</p>
              <RWebShare
                data={{
                  url:
                    location.pathname +
                    location.search.replace("?showPopup=true", ""),
                }}
              >
                {/* <img className="share-icon" src={shareIcon} alt="share unique campaign link" /> */}
                <Button className="share-btn">
                  <LinkOutlined />
                </Button>
              </RWebShare>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CampaignDetailsPage;
