const metaobjectGid = `gid://shopify/Metaobject`;
const productGid = `gid://shopify/Product`;

export const Gid = { metaobjectGid, productGid };

export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    window.scrollTo({ top: section.offsetTop, behavior: "smooth" });
  }
};

export const formatAmount = (number, locale = "en-US", currency = "USD") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(number);
};
