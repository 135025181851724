import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunk for fetching campaigns
export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URL}/public/campaigns/getActiveCampaigns`);

      // Check if the response status is not OK
      if (!response.ok) {
        throw new Error(response.message || 'Oops! We encountered an issue while loading the fundraisers. Please try again later. ');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: {
    campaigns: [],
    loading: false,
    error: null,
    success:'pending'
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success='pending';
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = action.payload;
        state.success='success';
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success='success';

      });
  },
});

export default campaignsSlice.reducer;
