import React from "react";
import { Form, Button, Input } from "antd";
import "./ContactUs.scss";
import { validatePhoneNumber } from "../../../Utils/PhoneNumberValidation";
import { contactUsSubmitClick } from "../../../services/ContactUS/contactUs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmitClick = async (values) => {
    try {
      const contactUsPayload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        message: values?.message,
      };

      const res = await contactUsSubmitClick(contactUsPayload);
      if (res?.email) {
        navigate("/thank-you");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="contact-us-container">
      <div className="contact-us-banner-image">
        <h1 className="contact-us-text">CONTACT US</h1>
      </div>

      <div className="contact-us-bar"></div>

      <div className="contact-us-form">
        <Form
          name="contactForm"
          form={form}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          layout="vertical"
          onFinish={onSubmitClick}
          onFinishFailed={onFinishFailed}
        >
          <div className="form-container">
            <div className="form-input-fields">
              <div>
                <Form.Item
                  label={<div className="form-label">First Name</div>}
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name",
                    },
                  ]}
                  required={false}
                >
                  <Input className="input" placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  label={<div className="form-label">Email</div>}
                  name="email"
                  required={false}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      required: true,
                      message: "Please enter your email address",
                    },
                  ]}
                >
                  <Input className="input" placeholder="Email" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label={<div className="form-label">Last Name</div>}
                  name="lastName"
                >
                  <Input className="input" placeholder="Last Name" />
                </Form.Item>

                <Form.Item
                  label={<div className="form-label">Phone Number</div>}
                  name="phoneNumber"
                  rules={[
                    { required: true, message: "Please enter phone number" },
                    ({ getFieldValue }) => ({
                      validator: validatePhoneNumber,
                    }),
                  ]}
                  required={false}
                >
                  <Input className="input" placeholder="Phone Number" />
                </Form.Item>
              </div>
            </div>
            <div className="">
              <Form.Item
                label={<div className="form-label">Message</div>}
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter your message",
                  },
                ]}
                required={false}
              >
                <Input.TextArea className="message" placeholder="Message" />
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className="button">
          {" "}
          <Button
            className="submit-button"
            onClick={() => {
              form.submit();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
