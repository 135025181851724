const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
  
    // Regex pattern to match exactly 10 digits
    const pattern = /^\d{10}$/;
  
    // Check if the phone number matches the pattern
    if (!pattern.test(value)) {
      return Promise.reject(new Error("Please enter a valid 10-digit phone number!"));
    }
  
    return Promise.resolve();
  };
  
  export { validatePhoneNumber };
  