import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss";
import nextBtn from "../../assets/images/carousel-right.svg";
import prevBtn from "../../assets/images/carousel-left.svg";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  if (onClick) {
    return (
      <img
        src={nextBtn}
        className={className}
        onClick={onClick}
        alt="Next slide"
      />
    );
  }
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  if (onClick) {
    return (
      <img
        src={prevBtn}
        className={className}
        onClick={onClick}
        alt="Next slide"
      />
    );
  }
}

function getSlides(ComponentName) {
  const windowWidth = window.innerWidth;
  let slides;

  switch (true) {
    case ComponentName === "ProductDetailCarousel" && windowWidth > 1440:
      slides = 4;
      break;
    case ComponentName === "ProductDetailCarousel" &&
      windowWidth >= 360 &&
      windowWidth <= 640:
      slides = 2;
      break;
    case ComponentName === "Product" && windowWidth > 1440:
      slides = 4;
      break;
    case windowWidth >= 360 && windowWidth <= 640:
      slides = 1;
      break;
    case windowWidth >= 641 && windowWidth <= 760:
      slides = 2;
      break;
    case windowWidth >= 761 && windowWidth <= 1024:
      slides = 2;
      break;
    case windowWidth >= 1920:
      slides = 4;
      break;
    default:
      slides = 3;
      break;
  }

  return slides;
}

export default function Carousel({
  data,
  Component,
  ComponentName,
  setMainImage,
  startDate,
  endDate,
}) {
  const slides = getSlides(ComponentName);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (data) {
    return (
      <Slider {...settings}>
        {data?.map((cardItem) => {
          return (
            <div className="slide" key={cardItem.id}>
              <Component
                key={cardItem.id}
                data={cardItem}
                setMainImage={setMainImage}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          );
        })}
      </Slider>
    );
  }
}
