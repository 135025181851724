import React, { useEffect, useState } from "react";
import "./OrderPage.scss";
import { getOrderSummaryById } from "../../../services/OrderPage/Orderpage";
import { Badge } from "antd";
import moment from "moment";
import Loader from "../../../Components/Loader/Loader";
import { formatAmount } from "../../../Utils/constants";

const OrderPage = () => {
  const [orderData, setOrderData] = useState({});
  const [loader, setLoader] = useState("");

  useEffect(() => {
    getOrderSummary();
  }, []);

  const getOrderSummary = async () => {
    try {
      setLoader(true);
      const splitArray = window.location.pathname.split("/");
      const orderId = splitArray[splitArray?.length - 1];
      const resp = await getOrderSummaryById(orderId);
      if (resp?.data?.order != null) {
        setOrderData(resp?.data);
      } else {
        setOrderData({});
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const getOrderStatus = (orderStatus, fulfillmentStatus) => {
    if (orderStatus === "open" && fulfillmentStatus === "unfulfilled") {
      return "Processing";
    } else if (orderStatus === "closed" && fulfillmentStatus === "fulfilled") {
      return "On the way";
    } else {
      return "Cancelled";
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {orderData?.order ? (
            <div className="order-container">
              <p className="order-heading">Order details</p>
              <div className="summary-container">
                <div className="order-details">
                  <div className="order-top-info">
                    <div className="order-info">
                      <p className="value">
                        Order{" "}
                        {orderData?.order?.fulfillmentOrders?.nodes?.[0]
                          ?.orderName || ""}
                      </p>
                      <p className="value">
                        {getOrderStatus(
                          orderData?.order?.fulfillmentOrders?.nodes?.[0]?.status?.toLowerCase(),
                          orderData?.order?.displayFulfillmentStatus?.toLowerCase()
                        )}
                      </p>
                    </div>
                    <div className="order-date">
                      {moment(orderData?.order?.createdAt).format("ll")}
                    </div>
                  </div>
                  <div className="order-details-info">
                    <div className="info-container">
                      <div className="order-contact-info">
                        <p className="label">Contact information</p>
                        {orderData?.order && (
                          <p className="value">
                            {orderData?.order?.purchasingEntity?.email}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="info-container">
                      <p className="label">Shipping address</p>
                      <p className="value">
                        {`${orderData?.order?.shippingAddress?.firstName}  ${orderData?.order?.shippingAddress?.lastName}`}{" "}
                      </p>
                      <p className="value">
                        {orderData?.order?.shippingAddress?.address1}
                      </p>
                      <p className="value">
                        {orderData?.order?.shippingAddress?.address2}
                      </p>

                      <p className="value">
                        {orderData?.order?.shippingAddress?.city}{" "}
                        {orderData?.order?.shippingAddress?.provinceCode}{" "}
                        {orderData?.order?.shippingAddress?.zip}
                      </p>
                      <p className="value ">
                        {orderData?.order?.shippingAddress?.country}
                      </p>
                      <p className="value ">
                        {orderData?.order?.shippingAddress?.phone}
                      </p>
                    </div>
                    <div className="info-container">
                      <p className="label">Payment Method</p>
                      <p className="value">
                        {`${orderData?.order?.paymentGatewayNames[0] ||
                          orderData?.order?.transactions[0]?.gateway
                          } - ${formatAmount(
                            orderData?.order?.totalPrice
                          )}`}{" "}
                      </p>
                    </div>
                    <div className="info-container">
                      <p className="label">Billing address</p>
                      <p className="value">
                        {orderData?.order?.billingAddress?.name}
                      </p>
                      <p className="value">
                        {orderData?.order?.billingAddress?.address1}
                      </p>

                      <p className="value">
                        {orderData?.order?.billingAddress?.city}{" "}
                        {orderData?.order?.billingAddress?.provinceCode}{" "}
                        {orderData?.order?.billingAddress?.zip}
                      </p>

                      <p className="value">
                        {orderData?.order?.billingAddress?.country}
                      </p>
                      <p className="value">
                        {orderData?.order?.billingAddress?.phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="order-summary">
                  <p className="order-summary-heading">Order summary</p>

                  <div className="order-summary-container-wrapper">
                    {orderData?.order?.lineItems?.nodes?.map((item) => (
                      <div className="order-summary-container">
                        {/* <Badge
                          count={item?.currentQuantity}
                          color="gray"
                          size="large"
                        > */}
                        <div className="image-wrapper">

                          <img
                            className="img"
                            src={item?.image?.src}
                            alt={item?.name}
                          />

                        </div>
                        {/* </Badge> */}
                        <div className="order-summary-details">
                          <p className="product-name">{item?.name}</p>
                          <div className="order-id-price">
                            <p className="unit-price">
                              {formatAmount(item?.originalUnitPrice)} x {item?.currentQuantity}
                            </p>
                            <p className="total-price">
                              {formatAmount(item?.originalTotal)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="total-container">
                    <div className="sub-total">
                      <p>Subtotal</p>
                      <p className="price">
                        {formatAmount(
                          orderData?.order?.currentSubtotalPriceSet
                            ?.presentmentMoney?.amount
                        )}
                      </p>
                    </div>
                    <div className="sub-total">
                      <p>Shipping</p>
                      <p className="price">
                        {formatAmount(
                          orderData?.order?.totalPrice -
                          orderData?.order?.currentSubtotalPriceSet
                            ?.presentmentMoney?.amount
                        )}
                      </p>
                    </div>
                    <div className="sub-total">
                      <p className="total">Total</p>
                      <p className="total">
                        {
                          orderData?.order?.currentTotalTaxSet?.presentmentMoney
                            ?.currencyCode
                        }{" "}
                        <span>{formatAmount(orderData?.order?.totalPrice)}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1>No order</h1>
          )}
        </>
      )}
    </>
  );
};

export default OrderPage;
