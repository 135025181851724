import React from "react";
import "./Product.scss";
import { Button, Modal } from "antd";
import { useMutation } from "@apollo/client";
import {
  CART_CREATE_MUTATION,
  CART_LINES_ADD_MUTATION,
} from "../../services/cart/CartQueries";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showMiniCart } from "../../Reducers/miniCartSlice";
import { Gid } from "../../Utils/constants";
import { formatAmount } from "../../Utils/constants";

function Product({ data, startDate, endDate }) {
  const dispatch = useDispatch();
  const [createCart] = useMutation(CART_CREATE_MUTATION);
  const [addItemToCart] = useMutation(CART_LINES_ADD_MUTATION);
  const { id } = useParams();
  const campaignId = `${Gid?.metaobjectGid}/${id}`;
  const merchandiseId = data?.variant_id[0];
  const location = useLocation();
  const navigate = useNavigate();
  const productId = data?.id?.match(/\d+$/)[0];

  // Helper function to remove time from a date object
  const stripTime = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const currentDate = stripTime(new Date());
  const startFormattedDate = stripTime(new Date(startDate));
  const endFormattedDate = stripTime(new Date(endDate));

  // Parse participant_id from URL query parameters
  const searchParams = new URLSearchParams(location.search);
  // const participantId = searchParams.get("participant_id");
  const participantId = searchParams
    ?.get("participant_id")
    ?.replace("?showPopup=true", "");

  const handleAddToCart = async () => {
    try {
      // Check local storage for cart ID and campaign ID
      const cartId = localStorage.getItem("cartId");
      const storedCampaignId = localStorage.getItem("campaignId");

      if (cartId) {
        // Check if the stored campaign ID matches the current campaign ID
        if (storedCampaignId !== campaignId) {
          // If not matching, show a modal to confirm clearing the cart
          Modal.confirm({
            title: "Clear Cart",
            content:
              "You already have items in your cart from another campaign. Do you want to clear that cart and add items from this campaign?",
            okButtonProps: {
              className: "custom-ok-button",
            },
            cancelButtonProps: {
              className: "custom-cancel-button",
            },
            onOk() {
              // Clear cart and create a new one with the current campaign ID
              localStorage.removeItem("cartId");
              localStorage.removeItem("campaignId");
              // Create new cart
              createNewCart();
            },
            onCancel() {
              // Do nothing if the user cancels
            },
          });
        } else {
          // Use existing cart
          const attributes = [
            {
              key: "campaign_id",
              value: campaignId,
            },
          ];
          if (participantId) {
            attributes.push({
              key: "participant_id",
              value: `${Gid?.metaobjectGid}/${participantId}`,
            });
          }
          await addItemToCart({
            variables: {
              cartId: cartId,
              lines: [
                {
                  quantity: 1,
                  merchandiseId: merchandiseId,
                  attributes: attributes,
                },
              ],
            },
          });

          toast.success("Item added to cart");
          dispatch(showMiniCart());
        }
      } else {
        // If cartId is not found, create a new cart
        createNewCart();
      }
    } catch (error) {
      // Handle error
      console.error("Error adding item to cart:", error);
      toast.error("Failed to add item to cart");
    }
  };

  const createNewCart = async () => {
    const attributes = [
      {
        key: "campaign_id",
        value: campaignId,
      },
    ];
    if (participantId) {
      attributes.push({
        key: "participant_id",
        value: `${Gid?.metaobjectGid}/${participantId}`,
      });
    }
    try {
      const { data: createCartData } = await createCart({
        variables: {
          input: {
            lines: [
              {
                quantity: 1,
                merchandiseId: merchandiseId,
                attributes: attributes,
              },
            ],
          },
        },
      });

      // Store new cart ID and campaign ID in local storage
      localStorage.setItem("cartId", createCartData?.cartCreate?.cart?.id);
      localStorage.setItem("campaignId", campaignId);
      toast.success("Item added to cart");
      if (createCartData?.cartCreate?.cart?.id) {
        dispatch(showMiniCart());
      }
    } catch (error) {
      console.error("Error creating new cart:", error);
      toast.error("Failed to create new cart");
    }
  };

  const redirectToProductDetail = () => {
    navigate(`/product-detail/${productId}`, {
      state: { referrer: location.pathname },
    });
  };

  return (
    <div className="product-container">
      <div onClick={redirectToProductDetail} className="product-top-container">
        <div className="product-image-container">
          <img
            src={data?.images[0]}
            className="product-image"
            alt={data?.title}
          ></img>
        </div>
        <div className="product-details-container">
          <div className="product-details-container">
            <div className="product-heading">{data?.title}</div>
            <div className="product-description">{data?.description}</div>
            <div className="product-price">{formatAmount(data?.price)}</div>
          </div>
        </div>
      </div>
      {currentDate >= startFormattedDate && currentDate <= endFormattedDate && (
        <Button className="cart-button" onClick={handleAddToCart}>
          Add to Cart
        </Button>
      )}
    </div>
  );
}

export default Product;
